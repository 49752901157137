import React, { forwardRef, PropsWithChildren } from 'react'

import BEMHelper from '../../helpers/bem'
import Icon from '../Icon'
import Link from '../Link'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

type ButtonProps = {
  children?: React.ReactNode
  type?: 'button' | 'submit'
  label?: string
  full?: boolean
  primary?: boolean
  large?: boolean
  small?: boolean
  xsmall?: boolean
  left?: boolean
  tag?: boolean
  circle?: boolean
  selected?: boolean
  more?: boolean
  as?: string
  icon?: string
  iconProps?: PropsWithChildren
  className?: string
  target?: string
  href?: string | { pathname: string; query?: Record<string, unknown> }
  onClick?: () => void
  disabled?: boolean
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(function BaseButton(
  {
    children,
    label,
    full,
    small,
    xsmall,
    large,
    left,
    as,
    href,
    tag,
    primary,
    circle,
    icon,
    more,
    selected,
    iconProps,
    className,
    ...props
  },
  ref,
) {
  const Node = href ? Link : 'button'
  const buttonProps = href ? { href, ...(as ? { as } : {}) } : { type: 'button' }

  return (
    // @ts-expect-error ref error
    <Node
      {...buttonProps}
      {...props}
      {...bem(
        '',
        { primary, circle, full, left, tag, selected, large, small, xsmall, more },
        className,
      )}
      {...(ref ? { ref } : {})}
    >
      <span {...bem('text')}>{label || children}</span>
      {icon && <Icon icon={icon} {...iconProps} {...bem('icon')} />}
      {more && <Icon icon="chevron" direction="down" {...bem('icon')} />}
    </Node>
  )
})

export default Button
